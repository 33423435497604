import React from "react";

export default function GoverningCharterText() {
  return (
    <div className="max-w-4xl prose sm:prose-lg mx-auto my-10 sm:my-20 docs-export">
      <p className="c11">
        <span className="c7">
          THE MISINFORMATION COMBAT ALLIANCE (ASSOCIATION)
        </span>
      </p>
      <p className="c11">
        <span className="c1 c0">GOVERNING FRAMEWORK</span>
      </p>
      <p className="c9">
        <span className="c0">
          The Misinformation Combat Alliance Association (MCAA) shall form the
          Misinformation Combat Alliance which shall have a Governing Council
          (hereinafter referred to as &ldquo;
        </span>
        <span className="c7">MCA Governing Council&rdquo;</span>
        <span className="c0">, &ldquo;</span>
        <span className="c7">MCA GC</span>
        <span className="c0">&rdquo;, or &ldquo;</span>
        <span className="c7">GC</span>
        <span className="c0 c1">
          &rdquo;). The MCA is a cross industry collaborative effort set up with
          the objective of combatting and limiting the spread of misinformation
          through targeted interventions and activities. As India confronts many
          challenges, there is one that is spreading quickly: an infodemic.
          Misinformation is eroding trust in media and institutions, creating
          unrest among different sections and communities of society; putting
          law, order, and public safety at risk, and is also impacting the
          health of individuals across the country.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          This problem cannot be solved by any single person or organization
          alone. There needs to be a collaborative effort addressing
          India&rsquo;s unique predicament to get our message across and create
          a more enlightened and aware digital society.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          At the same time, there is no single body representing fact checkers,
          media institutions, companies and organizations wanting to confront
          the challenge of misinformation. This is where the MCA would like to
          step in and represent, clarify, evolve, and amplify the interests of
          our stakeholders in confronting the challenges we face.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">VISION</span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          An enlightened digital society that is able to limit the spread of
          misinformation and negate bad actors and actions in the information
          ecosystem.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">MISSION</span>
      </p>
      <ul className="c6 lst-kix_list_3-0 start">
        <li className="c3 li-bullet-0">
          <span className="c7">Scale</span>
          <span className="c0">: </span>
          <span className="c1 c0">
            Reach every internet user in the country and make them digital media
            literate, thereby enabling them to combat misinformation.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c7">Impact</span>
          <span className="c0">: Change the behavior</span>
          <span className="c0 c5">&nbsp;of internet users to make them </span>
          <span className="c0">pause, think</span>
          <span className="c0 c5">&nbsp;and question before sharing</span>
          <span className="c0">&nbsp;and </span>
          <span className="c0 c5">amplifying misinformation</span>
          <span className="c0">. </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c7">Sustainability</span>
          <span className="c0">: </span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_3-1 start ml-8">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Initiate and engage in relevant dialogue and discussions with key
            stakeholders on matters pertaining to the ecosystem.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Represent the MCA</span>
          <span className="c0">&rsquo;</span>
          <span className="c1 c0">
            s viewpoint on key issues after discussion with all members of the
            MCA and the Governing Council.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Raise adequate resources to enable activities, projects, and events
            of the MCA.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">
          SCOPE OF ACTIVITIES OF THE MISINFORMATION COMBAT ALLIANCE
        </span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          The activities of the MCA will fall into the following broad
          categories :-
        </span>
      </p>
      <ol className="c6 lst-kix_list_2-0 start" start={1}>
        <li className="c3 li-bullet-0">
          <span className="c7">Literacy</span>
          <span className="c1 c0">&nbsp;</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Undertake media literacy, social media literacy, digital safety and
            other awareness activities through members and partners at scale,
            aiming to reach as many internet users as possible.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Partner with organizations to execute projects related to the
            information ecosystem.
          </span>
        </li>
      </ol>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_2-0" start={2}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">Innovation</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Data collection in order to undertake research, analyze trends and
            publish papers/reports.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Development of technologies, tools, and processes to aid in fact
            checking and verification, therefore curbing the spread of
            misinformation in furtherance of our mission.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Launch fellowship programs for pre-determined activities.
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-0" start={3}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">Advocacy</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Collectively formulate viewpoints on matters of policy, pertaining
            to the information ecosystem and make representations to the
            relevant stakeholders.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Ignite conversations around the information ecosystem, state of fact
            checking, media, trust, and society, etc. to initiate public debate
            through online and offline events.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Create key events for engagement between members of the
            organization.
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-0" start={4}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">Compliance</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_2-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Ensure compliance with relevant laws</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Raise funds to support projects from individuals and organi
          </span>
          <span className="c0">z</span>
          <span className="c0 c5">
            ations that can aid the MCA in its mission.{" "}
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
      </p>
      <p className="c9">
        <span className="c1 c7">
          STRUCTURE OF THE MISINFORMATION COMBAT ALLIANCE ASSOCIATION
        </span>
      </p>
      <ol className="c6 lst-kix_list_4-0 start" start={1}>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">MCAA and the Board of </span>
          <span className="c0">Directors</span>
          <span className="c1 c0">
            &nbsp;which will act on the advice of the MCA Governing Council
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">The MCA and the MCA Governing Council</span>
          <span className="c0">&nbsp;</span>
          <span className="c1 c0">
            which is the main decision-making body of the MCA
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            The MCA full time staff (henceforth called the &ldquo;
          </span>
          <span className="c7 c5">The</span>
          <span className="c0 c5">&nbsp;</span>
          <span className="c7 c5">Secretariat</span>
          <span className="c0">&rdquo; or </span>
          <span className="c7">&ldquo;Secretariat&rdquo;</span>
          <span className="c1 c0">) </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">MCA members</span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
      </p>
      <p className="c9">
        <span className="c1 c7">MEMBERSHIP TO THE MCA</span>
      </p>
      <ul className="c6 lst-kix_list_6-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">Any organi</span>
          <span className="c0">z</span>
          <span className="c0 c5">
            ation can become a member of the Misinformation Combat Alliance if
            they agree to abide by the charter of{" "}
          </span>
          <span className="c0">the Misinformation Combat Alliance</span>
          <span className="c1 c0">. These include:</span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_6-1 start ml-8">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Fact checking organi</span>
          <span className="c0">z</span>
          <span className="c1 c0">ations </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Media companies, projects, and initiatives{" "}
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Social media companies and digital platforms{" "}
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">OEMs and Telcos</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Industry Associations </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">OTTs: Audio and video</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Civil society and not for profit organizations
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Educational institutions </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Corporates</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">SMBs</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Government Bodies*</span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_6-0">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            All members need to agree to undertake specific deliverables towards
            achieving our mission. These deliverables will be specific to each
            member
          </span>
          <span className="c0">&nbsp;and will be recorded in an MoU. </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Membership to the MCA will be free for the first two years. Post
            that, the Governing Council will reconsider this based on the
            financial situation and needs of the Alliance.{" "}
          </span>
          <span className="c0">
            Should there be a need, and as agreed by the Governing Council,
            members can contribute towards the working capital requirements of
            the MCA.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            All new members need to be ratified by the Governing Council.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Membership does not mandate agreement on every project taken up by
            the Alliance.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Every founding member will sign an MoU indicating willingness to be
            a member. The first set of members will henceforth be called
            &ldquo;Founding Members&rdquo;
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">THE GOVERNING COUNCIL </span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0 start" start={1}>
        <li className="c3 li-bullet-0">
          <span className="c7 c5">Composition</span>
          <span className="c1 c0">
            : The &nbsp;Governing Council will have 15 (Fifteen) Members,
            divided as per the following :-
          </span>
        </li>
      </ol>
      <p className="c2 c18">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">President (Executive)</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Vice President (Working)</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Treasurer </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">GC Members (9) </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Independent Governing Council Members (3) &ndash; Nominated by the
            other 12 members of the board within 2 months of the{" "}
          </span>
          <span className="c0">Governing Council being constituted. </span>
        </li>
      </ol>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={2}>
        <li className="c3 li-bullet-0">
          <span className="c7 c5">Term</span>
          <span className="c1 c0">
            : Members of the Governing Council will be elected for a 2-year
            term. A Governing Council member can serve for a maximum of 2
            consecutive terms. <br />
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c7 c5">Appointment and Elections</span>
          <span className="c1 c0">: </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">The Governing Council </span>
          <span className="c0">(12 members) </span>
          <span className="c0 c5">
            will be elected by Members of the MCA. The same{" "}
          </span>
          <span className="c0">includes the President</span>
          <span className="c0 c5">
            , Vice-President and Treasurer. At the time of elections,{" "}
          </span>
          <span className="c0">m</span>
          <span className="c0 c5">
            embers of the MCA can nominate themselves or others{" "}
          </span>
          <span className="c0">who are members of the MCA</span>
          <span className="c0 c5">. </span>
          <span className="c0">E</span>
          <span className="c0 c5">
            lections will take place for each post in the{" "}
          </span>
          <span className="c0">
            Governing Council (except nominated members)
          </span>
          <span className="c0 c5">
            . Once elections are declared and nominations are in, Members will
            have{" "}
          </span>
          <span className="c0">10</span>
          <span className="c1 c0">
            &nbsp;calendar days to vote for the Governing Council.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">The posts of President, Vice President</span>
          <span className="c0">&nbsp;and</span>
          <span className="c0 c5">&nbsp;Treasurer in the Governin</span>
          <span className="c0">g Council </span>
          <span className="c0 c5">
            &nbsp;will also be up for vote and Members of the MCA can indicate
            who their choice for these posts is.{" "}
          </span>
          <span className="c0">
            Those standing for elections to the Governing Council can also
            choose if they would like to be considered for any of these three
            posts. One must be a member of the GC to be President, Vice
            President and Treasurer.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Independent Directors to the MCAA Board will be proposed by the
            President and
          </span>
          <span className="c0">&nbsp;</span>
          <span className="c0 c5">voted upon by the Governing Council.</span>
          <span className="c0">&nbsp;</span>
          <span className="c1 c0">
            Independent Directors will be nominated within 1 calendar month of
            the new Governing Council &nbsp;meeting for the first time.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            At the end of the 2-year term, the Alliance Manager at the
            Secretariat
          </span>
          <span className="c0">&nbsp;</span>
          <span className="c0 c5">will call for elections. </span>
          <span className="c0">For the constitution</span>
          <span className="c1 c0">
            &nbsp;of the next Governing Council, founding members and other
            members will vote to create the next Governing Council.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Founding members of the MCA</span>
          <span className="c0">&nbsp;</span>
          <span className="c0 c5">will be </span>
          <span className="c0">on the first MCA Governing Council</span>
          <span className="c0 c5">. Upon the expiry of the term of the </span>
          <span className="c0">first </span>
          <span className="c0 c5">Governing Council,</span>
          <span className="c0">&nbsp;members</span>
          <span className="c1 c0">
            &nbsp;can choose to opt for another term by entering the election
            process.
          </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={4}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">Resignation and Termination </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Resignation: A member of the </span>
          <span className="c0">GC</span>
          <span className="c1 c0">
            &nbsp;may resign if he/she is unable to fulfill responsibilities by
            giving one month&rsquo;s notice. The Governing Council &nbsp;shall
            meet to approve/reject the resignation.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Termination: If a member of the </span>
          <span className="c0">GC</span>
          <span className="c0 c5">
            &nbsp;fails to discharge his/her duties, or there is a breach of
            trust, he/she can be removed from the Council &nbsp;if 2/3
          </span>
          <span className="c0 c5 c19">rd</span>
          <span className="c0 c5">&nbsp;or more </span>
          <span className="c0">GC</span>
          <span className="c0 c5">
            &nbsp;members agree for such a removal with{" "}
          </span>
          <span className="c0">a quorum of at least 9 GC members. </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Upon resignation or termination, the Alliance Manager at the
            Misinformation Combat Alliance Association will have to call for
            elections within 5 calendar days of such a position falling vacant
            and conclude the elections process within{" "}
          </span>
          <span className="c0">3</span>
          <span className="c1 c0">
            0 calendar days of the vacancy. The newly elected Council
            &nbsp;member will continue for the remaining period of the original
            member. <br />
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-0" start={5}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">
            Meetings: Frequency, Notice, Decision Making, Quorum, Voting,
            Minutes
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Frequency: The Governing Council &nbsp;will meet once a month
            &ndash; there should be 12 meetings in a year. The gap between two
            successive meetings should not be more than 50 days.{" "}
          </span>
          <span className="c0">If the majority</span>
          <span className="c1 c0">
            &nbsp;of the Governing Council &nbsp;members agree that there is no
            need for a meeting, the same can be excluded for the relevant month.
            Meetings may be required additionally, over the decided period, and
            may be called by the President with agreement from the Governing
            Council .
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Meeting </span>
          <span className="c0">Form</span>
          <span className="c0 c5">: The Governing Council meetings may ha</span>
          <span className="c0">ppen</span>
          <span className="c0 c5">&nbsp;in person or online</span>
          <span className="c0">.</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Notice: Notice of the Governing Council meeting along with the
            agenda will be given by the President in conjunction with the
            Alliance Manager at least 2 weeks prior to the meeting.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Quorum: </span>
          <span className="c0">
            The quorum will be based on the type of decisions.{" "}
          </span>
          <span className="c0 c5">Certain </span>
          <span className="c0">types</span>
          <span className="c0 c5">&nbsp;of decisions may require a </span>
          <span className="c0">different</span>
          <span className="c0 c5">&nbsp;</span>
          <span className="c0">quorum. </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-1">
          <span className="c1 c0">Admitting new members: 7 members</span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Approving partnerships and alliances: 7 members
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Approving financial proposals: 11 members
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Removing members of the Governing Council and Members of the MCA :
            Atleast 9 members attending the meeting with 2/3rds majority
            approving the resolution.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Approving financial reports: Minimum of 9 members attending the
            meeting with 2/3rds majority approving the resolution. &nbsp; &nbsp;
            &nbsp;
          </span>
        </li>
        <li className="c4 li-bullet-2">
          <span className="c1 c0">
            Approving policy submissions and government engagements: Minimum of
            9 members attending the meeting with 2/3rds majority approving the
            resolution
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">Approving sponsorships: 9 members</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 ml-5" start={5} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            Minutes: Minutes of the meeting will be maintained by the Alliance
            Manager and circulated by the President within 5 calendar days of
            conducting a Governing Council &nbsp;
          </span>
          <span className="c0">m</span>
          <span className="c1 c0">eeting. </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Special invitees: The Alliance Manager will be a special invitee for
            all Governing Council &nbsp;meetings. The Council , by a simple
            majority, may choose to invite other individuals as they deem fit to
            discuss an item on the agenda.
          </span>
        </li>
        <li className="c12 c14 li-bullet-0">
          <span className="c0 c5">
            Decisions: The Governing Council will strive for consensus, and
            decisions can be made with a single majority among the quorum, if a
            first attempt to reach 2/3
          </span>
          <span className="c0 c5 c19">rd </span>
          <span className="c1 c0">majority fails. </span>
        </li>
      </ol>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={6}>
        <li className="c3 li-bullet-0">
          <span className="c1 c7">Roles &amp; Responsibilities </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={1} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">Strategy, Supervision and Organi</span>
          <span className="c0">s</span>
          <span className="c1 c0">ation</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Discuss and prepare the yearly strategy plans and obtain approval
            from the Governing Council before the end of each financial year for
            the upcoming year.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Provide guidance, advice, and direction to execute the yearly
            strategy plan.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c0 c5">Assist</span>
          <span className="c0">&nbsp;</span>
          <span className="c0 c5">The</span>
          <span className="c0">&nbsp;</span>
          <span className="c0 c5">Misinformation</span>
          <span className="c0">&nbsp;</span>
          <span className="c1 c0">
            Combat Alliance in troubleshooting challenges and providing support
            in operations when/where needed.
          </span>
        </li>
        <li className="c4 li-bullet-2">
          <span className="c1 c0">
            Evaluate the performance of the Alliance and take corrective action
            when/where needed.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Look into grievances and complaints brought forth by Members of the
            MCA. &nbsp;
          </span>
        </li>
        <li className="c4 li-bullet-2">
          <span className="c0 c5">Evaluate the Governing </span>
          <span className="c0">Structure</span>
          <span className="c1 c0">
            &nbsp;and recommend changes as needed for ratification by Members of
            the MCA. &nbsp;
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c0 c5">
            Determine if fees need to be charged for membership to the organi
          </span>
          <span className="c0">z</span>
          <span className="c1 c0">ation.</span>
        </li>
      </ol>
      <p className="c2 c16">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={2} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            The Governing Council President and Vice President
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-1">
          <span className="c0 c5">
            The President of the Governing Council will lead the MCA. He/she
            will act as a bridge between the Misinformation Combat Alliance
            Association
          </span>
          <span className="c0">&nbsp;and the </span>
          <span className="c0 c5">Governing </span>
          <span className="c0">Council</span>
          <span className="c1 c0">
            &nbsp;and will participate in the running and functioning of the
            Alliance.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c0">
            The Vice President will participate actively in the running of the
            MCA and with{" "}
          </span>
          <span className="c1 c0">
            support from the Alliance Manager, ensure execution of the plans.
            The Vice President will be the working link between the MCA
            Governing Council and The Secretariat
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            The Governing Council shall have an obligation to act in an
            impartial manner with respect to approvals/decisions regarding the
            MCA.
          </span>
        </li>
      </ol>
      <p className="c2 c17">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={3} type="a">
        <li className="c8 li-bullet-0">
          <span className="c0 c5">
            The Misinformation Combat Alliance Governing Council shall be bound
            to keep clear{" "}
          </span>
          <span className="c1 c0">
            and accurate accounts of the MCA, and at all reasonable times, at
            the request of the Governing Council and/or the Board of Directors
            of The Misinformation Combat Alliance Association, or members,
            furnish him/her with full and accurate information as to the same.
            <br />
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Funding</span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-1">
          <span className="c0 c5">Assist in </span>
          <span className="c0">fundraising</span>
          <span className="c1 c0">
            &nbsp;and partnerships to strengthen the MCA.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Approve financial plans and proposals put forth by the President and
            the Alliance Manager.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Evaluate and approve sponsorship and partnership proposals that help
            the MCA Governing Council bring in funds.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Oversee and approve financial reports prepared periodically.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Decide on membership fees (if any) for joining the alliance. <br />
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={5} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Hiring, Nominations and Appointments </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-2">
          <span className="c1 c0">
            Assist in the hiring of the Alliance Manager and other employees of
            the Misinformation Combat Alliance Association .
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c0 c5">Evaluate and </span>
          <span className="c0">approve an increase</span>
          <span className="c0 c5">&nbsp;in </span>
          <span className="c0">full-time</span>
          <span className="c1 c0">
            &nbsp;staff headcount for The Secretariat as and when needed.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Vote on Independent Directors and Independent Governing Council
            member nominations.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c1 c0">
            Accept or reject new member recommendations. New member admissions
            must be made within 20 calendar days unless (i) a further discussion
            is needed on the same which will not extend beyond 10 calendar days
            as agreed by at least &frac12; the Governing Council (ii) a majority
            has been reached by the time all members vote. While considering
            this, the deliverables from each new member will also be put forward
            in front of the GC .
          </span>
        </li>
      </ol>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-1 start ml-5" start={6} type="a">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Representations </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-2 start ml-10" start={1} type="i">
        <li className="c4 li-bullet-2">
          <span className="c1 c0">
            Represent the MCA to the larger community and external stakeholders.
          </span>
        </li>
        <li className="c4 li-bullet-1">
          <span className="c0">
            The Governing Council shall keep and shall take reasonable efforts
            to keep confidential all details and transactions relating to the
            MCA Governing Council and the state of affairs of the MCA except as
            otherwise compelled to disclose by operation of law and all
            Governing Council members and all other members shall sign
            Non-Disclosure Agreements.
            <br />
          </span>
        </li>
      </ol>
      <ol className="c6 lst-kix_list_7-0" start={7}>
        <li className="c3 li-bullet-0">
          <span className="c7">Remuneration</span>
          <span className="c0 c5">
            : Governing Council members are not financially compensated for
            their role on the board. In subsequent years, depending on the
            financial situation of the alliance, an honorarium may be paid. This
            will require ratification by members of the MCA Governing Coun
          </span>
          <span className="c0">cil</span>
          <span className="c0 c5">. The President</span>
          <span className="c0">, </span>
          <span className="c1 c0">
            Vice President and Treasurer will also not be compensated, and any
            decision to do so will need approval from the Council based on an
            evaluation of finances.
          </span>
        </li>
      </ol>
      <p className="c2 c18">
        <span className="c1 c0"></span>
      </p>
      <ol className="c6 lst-kix_list_7-0" start={8}>
        <li className="c9 c18 c20 li-bullet-3">
          <span className="c7 c5">&nbsp;Liability of Breach of Trust </span>
          <span className="c9 c18 block">
            <span className="c0 c5">Where the Governing Council </span>
            <span className="c0">M</span>
            <span className="c1 c0">
              embers and all other members commit a breach of trust, they shall
              be liable to make good the loss which the Misinformation Combat
              Alliance has thereby sustained, unless a Member has been induced
              by fraud to commit the breach, or being competent to contract, The
              Misinformation Combat Alliance has itself, without coercion or
              undue influence having been brought to bear on them, concurred in
              the breach, or subsequently acquiesced therein, with full
              knowledge of the facts of the case and of his/her rights as
              against the Member.
            </span>
          </span>
        </li>
      </ol>
      <p className="c9 !mt-24">
        <span className="c1 c7">THE MISINFORMATION COMBAT ALLIANCE</span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          The Misinformation Combat Alliance Association will have a Secretariat
          which will consist of full-time/contractual employees who will be
          responsible for the execution of the yearly plans and day-to-day
          functioning of the MCA. The terms of employment/contract will be
          approved by the MCA Governing Council along with the headcount and
          remuneration. It will be headed by an Alliance Manager who will have
          more support staff under him/her. The Alliance manager will also work
          closely with the MCA GC and the President and VIce President of the
          MCA to ensure smooth operations of the MCA.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c0">
          All decisions of the Governing Council shall be ratified by the Board
          of Directors of the Misinformation Combat Alliance Association in a
          Board Meeting.
        </span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9 !mt-12">
        <span className="c7">FINANCES</span>
        <span className="c0">&nbsp; &nbsp; &nbsp;</span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <ul className="c6 lst-kix_list_5-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            The MCA Governing Council will raise grants, subscriptions, gifts,
            sponsorships, donations, and partner contributions to support the
            execution of yearly{" "}
          </span>
          <span className="c0">plans</span>
          <span className="c1 c0">
            &nbsp;and maintain operations of the Misinformation Combat Alliance
            and the Misinformation Combat Alliance Association.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            All fund-raising proposals will be put before the Governing Council
            for approval. All the deliverables and expectations from the
            sponsor/donor will be clearly and transparently highlighted.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Yearly budgets and project/event budgets will be presented before
            the Governing Council for approval.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            The Governing Council will receive a monthly update on finances.
            Members of the MCA will get quarterly finance updates and reports.
            We will also update{" "}
          </span>
          <span className="c0">
            these details on our website at the periodicity mentioned here.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            The Misinformation Combat Alliance Association is permitted to apply
            for grant proposals and challenges on behalf of the MCA after
            obtaining approval from the Governing Council . &nbsp;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Currently, an account has been opened at the Kotak Mahindra Bank,
            Barakhamba Road Branch, Delhi. INR 100000 was deposited into the
            account from NC Media Networks Pvt Ltd.&rsquo;s account as initial
            capital. The President, Vice President, Treasurer and/or any other
            person authorized by the Governing Council shall operate the Bank
            Account.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            We will also apply for and comply with FCRA and open a bank account
            in the stipulated bank and branch.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Upon need and approval of the Governing Council, the MCA Governing
            Council may evaluate and undertake actions to open accounts as may
            be needed for the smooth functioning of operations.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">COMMUNICATIONS </span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <ul className="c6 lst-kix_list_9-0 start">
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            The Secretariat will be responsible for all communication with the
            Governing Council &nbsp;and members of the MCA.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            The Governing Council will receive updates every two weeks. Members
            of the MCA will receive monthly updates. &nbsp;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Most of the communication will be in the form of emailers,
            newsletters, documents, and reports. Slack will also be used for
            real time communication and updates.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Communication for carrying on the activities of the MCA which
            require voting will also take{" "}
          </span>
          <span className="c0">place</span>
          <span className="c1 c0">. </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Updates will consist of new partners, new initiatives, developments
            and progress, important information from the information ecosystem
            and partners, impact of the MCA&rsquo;s work, etc.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            There will be quarterly update calls with all members and one annual
            general meeting to present annual progress and the report of annual
            activities.{" "}
          </span>
          <span className="c1 c0">
            <br />
          </span>
        </li>
      </ul>
      <p className="c9">
        <span className="c1 c7">CONFLICT OF INTEREST</span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <ul className="c6 lst-kix_list_1-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Should the Governing Board be taking decisions which involve a
            member or his/her organi
          </span>
          <span className="c0">z</span>
          <span className="c1 c0">
            ation, such a member will recuse himself/herself from such
            discussions.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            The MCA Governing Council will collectively work towards achieving
            its{" "}
          </span>
          <span className="c0">mission rather than</span>
          <span className="c0 c5">&nbsp;for individual organi</span>
          <span className="c0">z</span>
          <span className="c0 c5">ations. While there</span>
          <span className="c0">&nbsp;is</span>
          <span className="c0 c5">&nbsp;a likelihood that the MCA </span>
          <span className="c0">will</span>
          <span className="c0 c5">
            &nbsp;get into transactions with members of the alliance as well
            those on the Governing Council, such agreements will be disclosed
            and made available to the Governing{" "}
          </span>
          <span className="c0">Council</span>
          <span className="c1 c0">
            . It will also be disclosed in our communications and reports.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Many grants are available to IFCN members,{" "}
          </span>
          <span className="c0">or to a consortium</span>
          <span className="c0 c5">
            &nbsp;where IFCN members are involved. In such a case, during the
            application process, all IFCN Signatories and other members of the
            Alliance
          </span>
          <span className="c0">&nbsp;</span>
          <span className="c1 c0">
            will be asked if they would like to partner for the application to
            the grant. Once broad contours of the grant proposal are prepared,
            it will be shared with the Governing Council for approval, who will
            have to give their response within a stipulated period as decided,
            keeping in mind deadlines to apply for such a grant.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Big Tech and Platforms: The MCA will work with Big Tech and social
            media platforms in the following capacities
          </span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_1-1 start ml-8">
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Big Tech/Platforms as a Sponsor</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Big Tech/Platforms as a Distribution Partner
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">Special projects/Joint projects</span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Given that many platforms could engage with us, we will be
            transparent about our interactions and ensure there is unity of
            purpose.
          </span>
        </li>
        <li className="c8 li-bullet-0">
          <span className="c1 c0">
            Also work with platforms to develop effective communication on how
            they deal with misinformation - this could also be a content in the
            modules we make.
          </span>
        </li>
      </ul>
      <ul className="c6 lst-kix_list_1-0">
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Some members of the MCA or MCA Governing Council may stand to
            benefit financially from certain activities that the MCA Governing
            Council will undertake. In all such instances, a member-wide RFP
            will be made, and such decisions will require representatives of
            such organizations who have submitted a response to the RFP from
            recusing themselves during the vote if represented in the Governing
            Council.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            For other activities, RFPs will be issued, and members can forward
            it to people/organizations they know. A transparent mechanism will
            be followed for reviewing and choosing the most qualified and
            economically sound execution partner. This policy will be reviewed
            in the interest of speed and execution periodically.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <p className="c2">
        <span className="c1 c7"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">ENGAGING WITH GOVERNMENT </span>
      </p>
      <ul className="c6 lst-kix_list_8-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">The MCA recogni</span>
          <span className="c0">z</span>
          <span className="c0 c5">es that engaging </span>
          <span className="c0">with the Government</span>
          <span className="c1 c0">
            &nbsp;and its authorities can be contentious. It will therefore only
            engage in good faith when the outcome is expected to be fruitful and
            will benefit the country, while also achieving the mission of the
            MCA.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Any financial proposal involving the government will require consent
            from the Governing Council .
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Any interaction between Governing Council members and the Government
            involving MCA needs to be disclosed in advance to the MCA Governing
            Council. We will{" "}
          </span>
          <span className="c0">largely</span>
          <span className="c0 c5">
            &nbsp;have interactions with the government for becoming a content
            distribution partner, giving{" "}
          </span>
          <span className="c0">inputs</span>
          <span className="c0 c5">&nbsp;</span>
          <span className="c0">on policy</span>
          <span className="c0 c5">&nbsp;</span>
          <span className="c0">matters,</span>
          <span className="c0 c5">&nbsp;jointly </span>
          <span className="c0">
            working on campaigns to prevent the spread of misinformation, giving
            an understanding of the information ecosystem etc.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Every proposal/engagement with the government will be evaluated on a
            case-on-case basis by the Governing Council.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            The MCA Governing Council will withdraw from discussions and
            engagements if there is even the slightest doubt of the MCA&rsquo;s
            independence being compromised.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c1 c0">
            Political parties and state governments may also engage with the
            MCA. We will present it before the Governing Council, and after
            approval carry out further conversations.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c9">
        <span className="c1 c7">CHANGES TO GOVERNING STRUCTURE</span>
      </p>
      <ul className="c6 lst-kix_list_10-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0 c5">
            Any changes to the governing structure of the MCAA, MCA, the
            Secretariat and MCA Governing Council can be proposed by the
            Governing Council. Alternativel
          </span>
          <span className="c0">
            y, members of the MCA can also propose changes{" "}
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0 c5">Such a change(s) will be presented </span>
          <span className="c0">to the</span>
          <span className="c0 c5">
            &nbsp;MCA Governing Council for a vote. Such voting needs to{" "}
          </span>
          <span className="c0">be concluded</span>
          <span className="c0 c5">
            &nbsp;within 10 calendar days of the changes being{" "}
          </span>
          <span className="c0">proposed and brought</span>
          <span className="c0 c5">
            &nbsp;to the GC unless majority GC members agree to consider the
            changes for a longer period of time.
          </span>
          <span className="c0">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c2 c12">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
      <p className="c2">
        <span className="c1 c0"></span>
      </p>
    </div>
  );
}
