import * as React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import GoverningCharterText from "../components/GoverningCharterText";

const GoverningCharterPage = () => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Navbar />

      <GoverningCharterText />

      <Footer />
    </div>
  );
};

export default GoverningCharterPage;
